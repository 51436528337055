<template>
  <div>
    <script type="application/javascript" src="https://js.stripe.com/v3/"></script>
    <v-breadcrumbs v-if="!isEmbeddedInApp" :items="breadcrumbItems"/>

    <v-card v-if="event && regConfig" :elevation="isEmbeddedInApp ? 0 : 2">
      <EventHeader :event="event"/>

      <v-card-title>{{$t('events.reg.confirm-page-title', { event: event.name })}}</v-card-title>

      <v-alert v-if="!$store.getters.isLoading && result && result.status == 'ERROR'" type="error" class="mx-4">
        <p>{{ result.msg }}</p>
        <p>{{$t('events.welcome.verification-code-mismatch')}}</p>
      </v-alert>

      <v-card-text v-if="reg">
        <h4>{{ $t('events.reg.step-info-title') }}</h4>
        <div>{{ $t('events.reg.given-name') }}: {{ reg.gn }}</div>
        <div>{{ $t('events.reg.family-name') }}: {{ reg.fn }}</div>
        <div>{{ $t('events.reg.email') }}: {{ reg.e }}</div>
      </v-card-text>

      <v-card-text v-if="regOption">
        <h4>{{ $t('events.reg.step-reg-option-title') }}</h4>
        <p>{{ regOption.name }}</p>
      </v-card-text>

      <v-card-text v-if="requirePayment">
        <h4>{{ $t('events.reg.fee-title') }}</h4>
        <p>{{ regOption.currency }} {{ regOption.amount.toLocaleString(language, {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}</p>
      </v-card-text>

      <v-card-text v-if="regOption">
        <v-btn :disabled="!reg" outlined color="primary" class="me-8" :to="{name: 'eventReg', params: {id: event.id}, query: {code: reg.v }}">{{ $t('shared.modify')}}</v-btn>
        <v-btn v-if="isFreeRegOption" :disabled="!reg" color="primary" :loading="$store.getters.isLoading" @click="submit">{{ $t('events.reg.register-now')}}</v-btn>
        <v-btn v-if="requirePayment" :disabled="!reg" color="primary" :loading="$store.getters.isLoading" @click="startPayment">{{ $t('events.reg.pay-now')}}</v-btn>
      </v-card-text>

      <br/>
    </v-card>

  </div>
</template>



<script>
import { mapGetters } from "vuex";
import { DateTime } from 'luxon'
import eventService from "@/services/eventService";
import VueMarkdown from '@/components/VueMarkdown.vue'
import ToggleButtonInput from '@/components/ToggleButtonInput.vue'
import EventHeader from '@/components/EventHeader.vue';
import EventJoinCard from '@/components/EventJoinCard.vue';
import profileService from "@/services/profileService";
import checkoutService from "@/services/checkoutService";
import { EventBus } from '@/plugins/eventbus.js';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();
import { getAuth } from "firebase/auth";
import siteData from '@/data/site.json'

export default {
  name: "EventJoin",
  components: {
    VueMarkdown,
    ToggleButtonInput,
    EventHeader,
    EventJoinCard,
  },
  props: {
  },
  data() {
    return {
      siteData: siteData,
      tenant: tenant,
      language: window.navigator.language,
      event: null,
      code: null,
      reg: null,
      result: null,
      valid: true,
    };
  },

  async mounted() {
    this.code = this.$route.params.code;
    await this.loadEvent(this.$route.params.id, this.$route.params.code);

    EventBus.$on('login-state-change', async user => {
      await this.getLoginStatus();
    });
    await this.getLoginStatus();

  },

  methods: {
      
    async getLoginStatus() {
      if (this.user) {
        var regResponse = await eventService.getRegistrationSummary(this.$route.params.id, this.$route.params.code);
        this.reg = regResponse.data;
      }
    },

    async loadEvent(id, code) {
      var response = await eventService.get(id);
      this.event = response.data;
      var meta = {
        title: this.event.name + ' on ' + tenant.name,
        description: this.event.description,
        image: this.event.img,
      };
      EventBus.$emit('page-header-change', meta);
    },
    
    async submit() {
      this.result = (await eventService.finalizeRegistration(this.event.id, this.code)).data;
      if (this.result && this.result.status == "OK") {
        this.$router.replace({ name: "eventWelcome", params: { event: this.event.id} });
        return;
      }
    },

    async startPayment() {
      const request = {};
      const response = await checkoutService.createRegistrationSession(this.event.id, this.code, this.isEmbeddedInApp ? 'app' : '', request);
      var stripe = tenant.stripeConfig && tenant.stripeConfig.connectedAccountId ? Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY, { stripeAccount: tenant.stripeConfig.connectedAccountId}) : Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
      const stripeResponse = stripe.redirectToCheckout({ sessionId: response.data.id });
      if (stripeResponse.error) {
        this.error = stripeResponse.error;
      }
    },

  },
  computed: {
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
    regConfig() {
      return this.event && this.event.reg_config;
    },
    regOption() {
      return this.regConfig && this.regConfig.options && this.reg && this.reg.ro && this.regConfig.options.find(x => x.id == this.reg.ro);
    },
    isFreeRegOption() {
      return this.regOption && (!this.regOption.amount || this.regOption.amount < 1);
    },
    requirePayment() {
      return this.regOption && this.regOption.amount && this.regOption.amount >= 1;
    },
    
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: this.event.name, exact: true, to: { name: 'event', params: {id: this.event.id}} },
        { text: this.$t('events.reg.title'), disabled: true },
      ];
    },
    ...mapGetters({
      user: "user"
    })
  },
  
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.loadEvent(to.params.id);
    next();
  },
};
</script>
<style lang="scss">
  .markdown-content {
    p { margin-bottom: 0 !important;}
  }
</style>

